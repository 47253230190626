@import 'src/theme/variables';

.eb-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px;

  .eb-anchor.eb-anchor-primary.eb-footer-link {
    &,
    &:hover {
      color: $color-neutral-60;
      font: $font-paragraph-14;
    }
  }

  .eb-footer-dot {
    color: $color-neutral-60;
  }
}
