@import 'src/theme/variables';

.eb-dropdown_filter-list-item {
  font: $font-paragraph-16;
  list-style: none;
  padding: 9px 12px;

  &:hover {
    background-color: $color-neutral-20;
  }

  .eb-dropdown_filter-check {
    float: right;
    height: 20px;
    width: 20px;
  }
}
