@import 'theme/variables';

.eb-password_tooltip {
  background-color: $color-neutral-10;
  border-radius: 16px;
  color: $color-neutral-60;
  display: flex;
  flex-flow: column wrap;
  height: 94px;
  padding: 16px 24px;
  position: static;
  width: auto;

  &.eb-password_tooltip-hidden {
    display: none;
  }

  .eb-password_tooltip-validation-indicator {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: $color-neutral-30;
    margin-right: 12px;

    &.eb-password_tooltip-validation-indicator-valid {
      background-color: $color-status-success;
    }
  }

  .eb-password_tooltip-heading {
    display: none;
  }

  .eb-password_tooltip-validation {
    flex: 0 0 18px;
    font: $font-paragraph-14;
    padding-bottom: 2px;
    padding-right: 5px;
  }

  .eb-icon {
    margin-right: 5px;
  }

  @media (max-width: 420px) {
    height: 113px;

    .eb-password_tooltip-validation {
      width: 113px;
      display: flex;
    }

    .eb-password_tooltip-validation-indicator {
      flex: 0 0 8px;
      margin-top: 5px;
    }
  }

  @media (max-width: 320px) {
    height: 125px;
    padding: 5px 20px;
  }
}
