@import 'src/theme/variables';

.eb-modal_body_action {
  .eb-modal-content {
    border-radius: 24px 24px 0 0;
  }

  .eb-modal_body_action-action-bar {
    background-color: $color-neutral-0;
    border-top: 1px solid $color-neutral-30;
    border-radius: 0 0 24px 24px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: auto 1fr auto auto; // Columns: action button, spacer, cancel button, save button
    height: 72px;
    padding: 16px 24px;
    width: 100%;
  }

  // Media queries - mobile screens
  @media (max-width: 719px) {
    .eb-modal-content {
      border-radius: unset;
      height: calc(100vh - 72px); // .eb-modal_body_action-action-bar height - 72px
      max-height: none;
      width: 100vw;

      // Instead of creating additional container, target heading for additional spacing
      > .eb-heading {
        margin-top: 32px;
      }
    }

    .eb-modal_body_action-action-bar {
      border-radius: unset;
    }
  }

  @media (max-width: 535px) {
    // Action bar with all three buttons
    &.eb-modal_body_action-buttons-all {
      .eb-modal-content {
        max-height: 500px;
      }

      .eb-modal_body_action-action-bar {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0;
        height: 144px;
      }

      .eb-modal_body_action-action-bar-button-action {
        text-align: center;
        width: 100%;
      }

      .eb-modal_body_action-action-bar-spacer {
        border: none;
        background-color: $color-neutral-20;
        height: 1px;
        margin: 11px auto 15px;
        width: 100%;
      }

      .eb-modal_body_action-action-bar-button-cancel,
      .eb-modal_body_action-action-bar-button-save {
        flex: 1;

        .eb-button_text {
          width: 100%;
        }
      }

      .eb-modal_body_action-action-bar-button-cancel {
        margin-right: 7px;
      }

      .eb-modal_body_action-action-bar-button-save {
        margin-left: 7px;
      }
    }

    // Action bar with only action button
    &.eb-modal_body_action-buttons-only-action {
      .eb-modal_body_action-action-bar {
        display: flex;
      }

      .eb-modal_body_action-action-bar-button-action {
        flex: 1;
        text-align: center;
      }
    }

    // Action bar with only save and cancel buttons
    &.eb-modal_body_action-buttons-only-save-cancel {
      .eb-modal_body_action-action-bar {
        display: flex;
        grid-gap: 0;
        justify-content: center;
      }

      .eb-modal_body_action-action-bar-button-save {
        margin-left: 16px;
      }
    }
  }

  // Media queries - desktop screens
  @media (max-height: 799px) and (min-width: 720px) {
    .eb-modal-content {
      /*
      .eb-modal-container margin-top/bottom - 32px
      .eb-modal_body_action-action-bar height - 72px
      32 * 2 + 72 = 136
      */
      max-height: calc(100vh - 136px);
    }
  }

  @media (max-height: 479px) and (min-width: 720px) {
    .eb-modal-content {
      /*
      .eb-modal-container margin-top - 32px
      .eb-modal_body_action-action-bar height - 72px
      32 + 72 = 104
      */
      max-height: calc(100vh - 104px);
    }
  }
}
