@import 'src/theme/variables';

.eb-icon_badge_count {
  position: absolute;
  left: 11px;
  top: -13px;
  background-color: $color-brand-primary-80;
  color: $color-neutral-0;
  border-radius: 40px;
  font: $font-heading-16;
  padding: 1px 7.5px;
  min-width: 24px;
}
