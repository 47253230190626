@import 'src/theme/variables';

.eb-header {
  padding: 32px 60px;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: calc($content-desktop + $nav-desktop-width - $content-side-padding * 2);
    width: 100%;
    margin: 0 auto;
  }

  &-logo {
    display: flex;

    &-title {
      padding: 0 16px;
      border-left: 1px solid $color-neutral-40;
      color: $color-neutral-60;
      font-size: 24px;
      letter-spacing: 2px;
    }

    &-image {
      width: 100%;
      height: 24px;
      margin-right: 16px;
    }
  }

  &-nav {
    display: flex;
    align-items: center;

    .eb-header-nav-upload-button {
      margin-right: 10px;
    }
  }

  .eb-header-nav-support {
    .eb-anchor {
      font: $font-paragraph-16;
    }
  }

  @media (max-width: 720px) {
    &.eb-header-logged-out {
      padding: 20px 0;
      margin: 0 auto;
    }

    .eb-header-nav-upload-button {
      display: none;
    }

    .eb-header-nav-support {
      display: none;
    }
  }
}
