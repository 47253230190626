@import 'theme/mixins';

.eb-api-spec {
  & &-heading {
    margin-bottom: 32px;
  }
  &-heading-version {
    font: $font-heading-16;
    color: $color-neutral-60;
  }

  .eb-api-section {
    margin: 50px auto;
    text-align: center;
  }
}
