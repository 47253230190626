@import 'src/theme/variables';

.eb-input {
  .eb-input_radio-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid $color-neutral-50;
    background-color: $color-neutral-0;
    border-radius: 50%;
    box-shadow: 0 0 0 2px transparent, 0 0 0 4px transparent;
  }

  // /* Focus */
  &.eb-input-focus .eb-input_radio .eb-input_radio-container {
    box-shadow: 0 0 0 2px $color-neutral-0, 0 0 0 4px $color-brand-primary-60;
  }

  /* The Container */
  .eb-input_radio {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 28px;

    .eb-input_radio-text {
      font: $font-paragraph-16;
      margin: 8px;
      vertical-align: middle;
    }

    /* Creating custom radio button */
    .eb-input_radio-button::after {
      content: '';
      position: absolute;
      top: 7px;
      left: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-neutral-0;
      display: none;
    }

    /* Hide the browser's default radio button */
    .eb-input-control {
      position: absolute;
      opacity: 0%;
      cursor: pointer;
      height: 0;
      width: 0;

      &:disabled ~ .eb-input_radio-container {
        background-color: $color-neutral-10;
        cursor: not-allowed;
        opacity: 30%;
      }

      &:checked ~ .eb-input_radio-container {
        background-color: $color-brand-primary-80;
        border: 1px solid $color-brand-primary-80;
      }

      &:hover:not([disabled]):checked ~ .eb-input_radio-container {
        border: 1px solid $color-brand-primary-70;
        background-color: $color-brand-primary-70;
      }

      &:checked ~ .eb-input_radio-button::after {
        display: block;
      }
    }
  }
}
