@import 'src/theme/variables';

.eb-mfa_code {
  text-align: center;
  margin-top: -88px;

  .eb-mfa_code-image {
    width: 64px;
    height: 64px;
  }

  .eb-mfa_code-heading {
    margin-bottom: 12px;
  }

  .eb-mfa_code-image,
  .eb-mfa_code-text,
  .eb-mfa_code-inputs {
    margin-bottom: 24px;
  }

  & &-button.eb-mfa_code-button {
    width: 160px;
  }
}
