.eb-modal_upload_file {
  .eb-modal-content {
    height: 450px;
    padding-top: 25px;
  }

  .eb-modal_upload_file-content {
    margin-top: 40px;
  }
}
