@import 'src/theme/variables';

.eb-icon_account {
  border-radius: 50%;
  color: $color-neutral-0;
  display: inline-block;
  font: $font-heading-16;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  min-width: 40px;

  &.eb-icon_account-invitee {
    opacity: 30%;
  }

  &.eb-icon_account-color-1 {
    background-color: $color-brand-primary-80;
  }

  &.eb-icon_account-color-2 {
    background-color: $color-additional-brand-orange;
  }

  &.eb-icon_account-color-3 {
    background-color: $color-brand-primary-70;
  }

  &.eb-icon_account-color-4 {
    background-color: $color-additional-aqua-dark;
  }

  &.eb-icon_account-color-5 {
    background-color: $color-additional-purple-dark;
  }
}
