@import 'src/theme/variables';

.eb-files {
  & &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;

    &-filter-buttons {
      display: flex;
      gap: 8px;
    }
  }

  &-file-info {
    display: flex;
    flex-wrap: wrap;
    color: $color-neutral-60;
    margin-top: 4px;
  }
}
