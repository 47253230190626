.eb-error_server {
  margin-top: 125px;
  text-align: center;

  .eb-error_server-heading {
    margin: 37px auto 12px;
  }

  @media (max-width: 720px) {
    margin-top: 75px;

    .eb-error_server-heading {
      margin: 16px auto 8px;
    }
  }
}
