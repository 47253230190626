@import 'theme/variables';

.eb-modal_member_details {
  .eb-modal-content {
    max-height: 703px;
    display: grid;
    grid-template-rows: 32px auto;
    row-gap: 24px;
  }

  .eb-modal_member_details_member_row {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
  }

  .eb-input {
    margin: 8px 0 8px;
  }

  .eb-modal_member_details-footer {
    display: flex;
  }

  & &-port-button {
    display: flex;
    align-items: center;
    color: $color-brand-primary-90;
    font: $font-heading-16;
    padding-right: 25px;
    .eb-icon {
      margin-right: 12px;
    }
  }

  & &-term-button {
    display: flex;
    align-items: center;
    color: $color-status-error;
    font: $font-heading-16;
    padding-right: 25px;
    .eb-icon {
      margin-right: 12px;
    }
  }

  .eb-modal_body_action .eb-modal_body_action-action-bar {
    grid-template-columns: auto 0 0 150px;
    align-items: center;
  }

  @media (min-height: 729px) and (min-width: 720px) {
    .eb-modal-content {
      min-height: 650px;
    }
  }
}
