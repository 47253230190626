@import 'theme/variables';

.eb-organizations_header {
  .eb-organizations_header-heading {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    margin-bottom: 12px;
  }

  .eb-organizations_header-heading-text {
    word-break: break-all;
  }

  .eb-organizations_header-heading-button {
    margin-top: 5px;
  }

  .eb-organizations_header-sub-heading {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }

  .eb-organizations_header-sub-heading-link {
    font: $font-heading-16;
    color: $color-brand-primary-80;

    &:hover {
      text-decoration: underline;
    }
  }
}
