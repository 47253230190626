@import 'theme/variables';

.eb-layout_page_nav {
  max-width: $nav-desktop-width;

  .eb-layout_page_nav-link {
    align-items: center;
    border-radius: 24px;
    color: $color-neutral-60;
    display: grid;
    font: $font-heading-16;
    grid-template-columns: 39px 1fr;
    margin: 2px 0;
    padding: 20px 22px;
    text-decoration: none;

    .eb-icon {
      color: $color-neutral-60;
      margin-right: 35px;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: $color-neutral-10;
      color: $color-neutral-100;

      .eb-icon {
        color: $color-neutral-100;
      }
    }

    &:focus-visible {
      outline-color: $color-brand-primary-80;
    }

    &.eb-layout_page_nav-link-active {
      background-color: $color-neutral-10;
    }

    &.eb-aria_link-disabled {
      cursor: not-allowed;
      color: $color-neutral-40;

      .eb-icon {
        color: $color-neutral-40;
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background-color: $color-neutral-0;
      }
    }
  }

  @media (max-width: 1260px) {
    max-width: none;

    .eb-layout_page_nav-link {
      grid-template-columns: 23px 1fr;
    }

    .eb-layout_page_nav-title {
      display: none;
    }
  }

  @media (max-width: $mobile) {
    display: none;
  }
}
