@import 'theme/variables';

.eb-file_upload {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-neutral-0;
  height: 190px;
  min-height: 190px;
  padding: 40px 0 32px;
  border: 2px dashed $color-neutral-30;
  border-radius: 16px;

  &.eb-file_upload-drag-over {
    background-color: $color-brand-primary-20;
    border-color: $color-brand-primary-80;
    padding: 54px 0;
  }

  &.eb-file_upload-drag-invalid {
    background-color: $color-status-error-bg;
    border-color: $color-status-error;
  }

  .eb-file_upload-label {
    font: $font-heading-18;
    color: $color-neutral-100;
  }

  .eb-file_upload-label-drag-over {
    margin-top: 16px;
    font: $font-heading-18;
    color: $color-brand-primary-80;
  }

  .eb-file_upload-label-drag-over-invalid {
    margin-top: 16px;
    font: $font-heading-18;
    color: $color-status-error;
  }

  .eb-file_upload-divider {
    font: $font-paragraph-14;
    color: $color-neutral-60;
    margin: 16px 0;
  }

  .eb-file_upload-button {
    min-width: 220px;
  }

  .eb-file_upload-field {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0%;
    border: none;
    font-size: 18px;
    cursor: pointer;
    text-transform: none;

    &:focus {
      outline: none;
    }
  }
}
