@import 'theme/variables';

.eb-organization {
  border-bottom: 1px solid $color-neutral-20;

  .eb-organization-section {
    display: grid;
    grid-template-columns: 1fr auto auto;
    padding: 24px 0;
    align-items: center;
  }

  &.eb-aria_link-disabled {
    cursor: default;
  }

  .eb-organization-tag {
    padding-right: 20px;
    text-transform: capitalize;
  }

  .eb-organization-details-heading-code {
    color: $color-neutral-60;
  }

  .eb-organization-details-heading {
    align-items: flex-start;
    margin-bottom: 8px;

    &,
    &-link {
      display: flex;
    }

    &-name,
    &-code {
      word-break: break-all;
      margin-right: 8px;
    }

    &-code {
      font: $font-paragraph-14;
      line-height: 24px;
    }

    &-notification {
      min-width: 20px;
      color: $color-neutral-60;
      cursor: pointer;
      margin-top: 2px;

      &-active {
        color: $color-status-success;
      }

      &.eb-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &:hover {
    .eb-organization-details-heading-notification {
      visibility: visible;
    }
  }

  .eb-organization-details-heading-notification {
    min-width: 20px;
    color: $color-neutral-60;
    cursor: pointer;
    margin-top: 2px;
    visibility: hidden;

    &-active {
      color: $color-status-success;
      visibility: visible;
    }

    &.eb-icon {
      width: 20px;
      height: 20px;
    }
  }

  .eb-organization-date {
    text-align: right;
  }

  @media (max-width: $mobile) {
    &.eb-organization {
      grid-template-columns: auto;
    }

    .eb-organization-tag {
      padding-right: 8px;
    }

    .eb-organization-details-heading-link {
      flex-flow: column;
    }
  }
}
