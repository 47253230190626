@import 'src/theme/variables';

.eb-icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  position: relative;
  vertical-align: top;

  svg {
    height: 100%;
    width: 100%;
  }

  &.eb-icon-exception {
    height: 20px;
    width: 20px;

    svg {
      color: $color-neutral-50;
    }
  }

  &.eb-icon-disabled {
    // stylelint-disable-next-line declaration-no-important
    cursor: no-drop !important;

    svg {
      color: $color-neutral-30;
    }
  }

  .eb-icon_tooltip {
    position: absolute;
    margin: 0;
    background-color: $color-brand-primary-100;
    border-radius: 12px;
    color: $color-neutral-0;
    padding: 9px 15px;
    text-align: center;
    font: $font-paragraph-14;
    width: max-content;
    bottom: unset;
    top: 42px;
    right: -20px;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      border-width: 5px;
      border-style: solid;
      bottom: 100%; /* At the top of the tooltip */
      right: 26px;
      margin-left: -5px;
      border-color: transparent transparent $color-brand-primary-100 transparent;
    }
  }
}
