@import 'src/theme/variables';

.eb-user_management {
  & &-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .eb-user_management-subheading {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-neutral-20;

    .eb-user_management-subheading-dot {
      color: $color-neutral-60;
      font-size: 10px;
    }
  }

  .eb-user_management-invite-button.eb-button_text-text {
    color: $color-neutral-100;
    font: $font-heading-18;
    margin-top: 25px;
    padding-left: 10px;

    .eb-icon {
      color: $color-neutral-100;
      margin-right: 28px;
    }
  }
}
