@import 'src/theme/variables';

.eb-pagination {
  color: $color-neutral-60;
  display: flex;
  justify-content: space-between;
  font: $font-paragraph-16;
  margin-top: 25px;

  .eb-pagination-items-per-page,
  .eb-pagination-control {
    align-self: center;
  }

  .eb-pagination-control {
    justify-self: center;
  }

  .eb-pagination-control-chevron {
    color: $color-neutral-50;
    cursor: pointer;
    width: 45px;

    &:first-child {
      margin-right: 17px;
    }

    &:last-child {
      margin-left: 17px;
    }

    &.eb-pagination-control-chevron-disabled {
      cursor: no-drop;
      opacity: 60%;
    }
  }

  .eb-pagination-control-page {
    cursor: pointer;
    padding: 0 9px;

    &.eb-pagination-control-page-selected {
      color: $color-neutral-100;
      font-weight: 600;
    }

    &.eb-pagination-control-page-ellipsis {
      cursor: auto;
    }
  }

  @media (max-width: $mobile) {
    grid-auto-rows: 50px;
    grid-template-columns: 0 1fr 0;

    .eb-pagination-items-per-page {
      visibility: hidden;
    }

    .eb-dropdown_filter {
      display: none;
    }
  }
}
