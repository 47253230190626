@import 'src/theme/variables';

.eb-filter_toggle {
  &:not(&-open) {
    .eb-button_icon-button.eb-button_icon-button-primary:not(:hover) {
      background-color: transparent;

      .eb-icon svg {
        color: $color-neutral-100;
      }
    }
  }
}
