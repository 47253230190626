@import 'src/theme/variables';

.eb-aria_link {
  // Default styles
  cursor: pointer;

  .eb-aria_link-icon {
    color: $color-brand-primary-80;
    height: 20px;
    width: 20px;
  }

  // Intent
  // Scope default button styles to separate class so there are no default styles
  // This way, button styles don't need to be specifically reverted if styles other than button are needed
  &.eb-aria_link-button {
    display: inline-block;
    border: none;
    border-radius: 24px;
    padding: 10.5px 36px;
    box-shadow: 0 0 0 2px transparent;
    text-align: center;
    font: $font-heading-16;
    color: $color-neutral-0;

    &:focus-visible {
      outline: 2px solid $color-brand-primary-60;
      outline-offset: 2px;
    }

    &.eb-aria_link-disabled {
      cursor: not-allowed;
    }
  }

  &.eb-aria_link-primary {
    background-color: $color-brand-primary-80;

    .eb-icon {
      color: $color-neutral-0;
    }

    &:hover,
    &:active {
      background-color: $color-brand-primary-70;
    }

    &.eb-aria_link-disabled {
      background-color: $color-brand-primary-60;
    }
  }

  &.eb-aria_link-secondary {
    background-color: $color-brand-primary-40;
    color: $color-brand-primary-80;

    .eb-icon {
      color: $color-brand-primary-80;
    }

    &:hover,
    &:active {
      background-color: $color-brand-primary-50;
    }

    &.eb-aria_link-disabled {
      background-color: $color-brand-primary-30;
      color: $color-brand-primary-60;

      .eb-icon {
        color: $color-brand-primary-60;
      }
    }
  }

  &.eb-aria_link-text {
    background-color: transparent;
    color: $color-brand-primary-80;

    &:hover,
    &:active {
      background-color: $color-brand-primary-40;
      color: $color-brand-primary-80;

      .eb-icon {
        color: $color-brand-primary-80;
      }
    }

    &.eb-aria_link-disabled {
      color: $color-brand-primary-60;

      &:hover {
        background-color: transparent;
      }

      .eb-icon {
        color: $color-brand-primary-60;
      }
    }
  }

  // Display
  &.eb-aria_link-display-inline {
    display: inline;
  }

  &.eb-aria_link-full-width {
    width: 100%;
  }
}
