@import 'src/theme/variables';

.eb-file_management {
  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;

    &-filter-buttons {
      display: flex;
      gap: 8px;
    }
  }

  &-file-info {
    color: $color-neutral-60;
    margin-top: 4px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }

    &-uploaded {
      margin-right: 12px;
    }

    & .file-id {
      border-left: 5px solid $color-additional-sea-dark;
      padding-left: 8px;
      word-break: break-word;
    }

    &-test {
      border-left: 5px double orange;
      padding-left: 8px;
    }
  }

  &-summary-counts {
    display: flex;
    gap: 12px;
    margin-top: 10px;

    &-item {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      border: 1px solid $color-neutral-30;
      border-radius: 16px;
      padding: 12px;
      font: $font-heading-16;
    }
  }
}
