@import 'src/theme/variables';

.eb-dot {
  margin: 0 6px;

  &.eb-dot-red {
    color: $color-status-error;
  }

  &.eb-dot-gray {
    color: $color-neutral-60;
  }

  @media (max-width: 720px) {
    margin: 0 6px;
  }
}
