@import 'theme/variables';

.eb-modal_file_upload_failure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 70px;

  .eb-modal_file_upload_failure-img {
    width: 64px;
    height: 64px;
    color: $color-status-error;
  }

  .eb-modal_file_upload_failure-heading {
    margin: 20px 0;
  }

  .eb-modal_file_upload_failure-button {
    margin: 32px 0 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
