@import 'src/theme/variables';

.eb-form {
  .eb-form-toast {
    border-radius: 12px;
    font: $font-heading-16;
    margin-bottom: 23px;
    padding: 9px;
    text-align: center;
  }

  .eb-form-toast-error {
    background-color: $color-status-error-bg;
    color: $color-status-error;

    .eb-anchor {
      color: $color-status-error;
      font: $font-heading-16;
      text-decoration: underline;

      &:hover {
        color: $color-status-error;
        text-decoration: none;
      }
    }
  }

  .eb-form-toast-pending {
    background-color: $color-status-success-bg;
    color: $color-status-success;
  }
}
