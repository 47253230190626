@import 'theme/mixins';

.eb-api_spec_layout {
  select,
  .btn.btn,
  .btn-group .btn.btn {
    @include button();
  }

  input[type='text'],
  input[type='password'] {
    @include input();
  }

  select,
  .btn.btn-done,
  .btn.btn-clear,
  .btn.try-out__btn {
    @include button-secondary();
  }

  .btn.execute {
    @include button-primary();
  }

  .btn-group {
    gap: 12px;
  }

  .opblock-tag {
    font: $font-heading-26;
    color: $color-neutral-100;
    text-transform: capitalize;
    padding: 0;
    margin-bottom: 24px;
    border: none;

    &:hover {
      background: none;
    }
  }

  span:not(:first-child) .opblock-tag {
    margin-top: 32px;
  }

  .info,
  .wrapper {
    margin: 0;
    padding: 0;
  }

  .info .title {
    font: $font-heading-32;
    color: $color-neutral-100;
    margin: 0 0 32px;
  }

  &-env {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 24px;

    &-info {
      color: $color-neutral-100;
    }

    &-info-url {
      margin-top: 4px;
      font: $font-heading-18;
    }

    .eb-input > label {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  .opblock .opblock-summary.opblock-summary {
    border: none;
    padding: 0;
  }

  .opblock.opblock-post {
    border-radius: 24px;
    justify-content: space-between;
    padding: 14px 24px 14px 16px;
    border: none;
    box-shadow: none;

    & + & {
      margin-top: 12px;
    }

    svg {
      width: 16px;
      min-width: 16px;
    }

    .opblock-summary-method {
      border-radius: 24px;
      padding: 9px 16px;
      font: $font-heading-14;
      text-shadow: none;
    }

    .opblock-summary-path {
      font: $font-heading-16;
    }

    .opblock-summary-description {
      font: $font-paragraph-16;
    }

    .opblock-summary .view-line-link {
      display: none;
    }

    .model-box-control:focus,
    .models-control:focus,
    .opblock-summary-control:focus {
      outline: none;
    }

    .model-box-control:focus-visible,
    .models-control:focus-visible,
    .opblock-summary-control:focus-visible {
      outline: 2px solid $color-brand-primary-100;
      outline-offset: 6px;
    }

    .tab-header .tab-item.active h4 span:after {
      background: $color-brand-primary-90;
    }

    .opblock-section-header {
      box-shadow: none;
      margin-top: 12px;
      border-radius: 12px;
    }
  }

  .opblock-summary-control {
    display: flex;
    gap: 12px;

    &::after {
      display: block;
      content: '+';
      font: $font-paragraph-24;
      color: $color-brand-primary-90;
    }
  }

  .opblock-post.is-open .opblock-summary-control::after {
    content: '-';
  }

  .opblock-tag-section:nth-child(2n) .opblock.opblock-post {
    background: $color-additional-brand-beige;

    .opblock-summary-method {
      background-color: $color-additional-brand-orange;
      color: $color-neutral-0;
    }

    .opblock-summary-control::after {
      color: $color-additional-brand-orange;
    }
  }

  .opblock-tag-section:nth-child(2n + 1) .opblock.opblock-post {
    background: $color-brand-primary-40;

    .opblock-summary-method {
      background-color: $color-brand-primary-50;
      color: $color-brand-primary-90;
    }
  }

  .opblock-section-request-body .opblock-section-header.opblock-section-header,
  .response-controls,
  .authorization__btn,
  .execute-wrapper:empty,
  .opblock-control-arrow {
    display: none;
  }
}
