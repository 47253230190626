@import './variables';

@mixin input {
  padding: 9px 16px;
  box-shadow: 0 0 0 2px transparent;
  font: $font-paragraph-16;
  border-radius: 12px;
}

@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 36px;
  box-shadow: 0 0 0 2px transparent;
  font: $font-heading-16;
  transition: transform ease-in-out 0.2s;
  border-radius: 24px;
  border: solid 2px;
  text-align: center;
}

@mixin button-secondary {
  background-color: transparent;
  color: $color-brand-primary-80;
  border: $color-brand-primary-80 solid 2px;

  svg {
    color: $color-brand-primary-80;
    fill: $color-brand-primary-80;
  }

  &:hover,
  &:active,
  &:focus-visible {
    background-color: $color-brand-primary-90;
    border-color: $color-brand-primary-90;
    color: $color-neutral-0;

    svg {
      color: $color-neutral-0;
      fill: $color-neutral-0;
    }
  }

  &[disabled] {
    background-color: $color-neutral-0;
    color: $color-brand-primary-60;
    border-color: $color-brand-primary-60;

    svg {
      color: $color-brand-primary-60;
      fill: $color-brand-primary-60;
    }
  }
}

@mixin button-primary {
  background-color: $color-brand-primary-80;
  color: $color-neutral-0;
  border: $color-brand-primary-80 solid 2px;

  svg {
    color: $color-neutral-0;
    fill: $color-neutral-0;
  }

  &:hover,
  &:active,
  &:focus-visible {
    background-color: $color-brand-primary-90;
    border-color: $color-brand-primary-90;
    color: $color-neutral-0;

    svg {
      color: $color-neutral-0;
      fill: $color-neutral-0;
    }
  }

  &[disabled] {
    background-color: $color-brand-primary-60;
    color: $color-neutral-0;
    border-color: $color-neutral-0;

    svg {
      color: $color-neutral-0;
      fill: $color-neutral-0;
    }
  }
}
