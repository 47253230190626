@import 'src/theme/variables';

.eb-layout_auth {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // Content
  .eb-layout_auth-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .eb-form {
    margin-top: 32px;
  }

  .eb-layout_auth-content-container {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: $mobile) {
    padding: 0 24px;
  }
}
