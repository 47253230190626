@import 'src/theme/variables';

.eb-input {
  margin: 16px 0;

  // Input
  .eb-input-control {
    border: none;
    background-color: $color-neutral-0;
    border-radius: 12px;
    box-shadow: 0 0 0 1px $color-neutral-40;
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    -webkit-box-shadow: 0 0 0 1px $color-neutral-40; /* stylelint-disable-line property-no-vendor-prefix */
    -moz-box-shadow: 0 0 0 1px $color-neutral-40; /* stylelint-disable-line property-no-vendor-prefix */
    color: $color-neutral-100;
    font: $font-paragraph-16;
    padding: 9px 12px;
    text-overflow: ellipsis;
    position: relative;
    width: 100%;

    &:hover {
      box-shadow: 0 0 0 1px $color-neutral-50;
      -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      -webkit-box-shadow: 0 0 0 1px $color-neutral-50; /* stylelint-disable-line property-no-vendor-prefix */
      -moz-box-shadow: 0 0 0 1px $color-neutral-50; /* stylelint-disable-line property-no-vendor-prefix */
    }
  }

  &-readonly .eb-input-control {
    background-color: $color-neutral-10;
  }

  .eb-input-control::placeholder {
    font: $font-paragraph-16;
    color: $color-neutral-50;
  }

  // Focus
  &.eb-input-focus {
    .eb-input-control {
      box-shadow: 0 0 0 2px $color-brand-primary-80;
      -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      -webkit-box-shadow: 0 0 0 2px $color-brand-primary-80; /* stylelint-disable-line property-no-vendor-prefix */
      -moz-box-shadow: 0 0 0 2px $color-brand-primary-80; /* stylelint-disable-line property-no-vendor-prefix */
    }
  }

  // Error
  &.eb-input-error {
    .eb-input-control {
      background-color: unset;
      box-shadow: 0 0 0 2px $color-status-error;
      -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      -webkit-box-shadow: 0 0 0 2px $color-status-error; /* stylelint-disable-line property-no-vendor-prefix */
      -moz-box-shadow: 0 0 0 2px $color-status-error; /* stylelint-disable-line property-no-vendor-prefix */
    }
  }

  // Disabled
  .eb-input-control:disabled {
    background-color: $color-neutral-10;
    box-shadow: 0 0 0 1px $color-neutral-20;
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    -webkit-box-shadow: 0 0 0 1px $color-neutral-20; /* stylelint-disable-line property-no-vendor-prefix */
    -moz-box-shadow: 0 0 0 1px $color-neutral-20; /* stylelint-disable-line property-no-vendor-prefix */
    color: $color-neutral-100;
    cursor: not-allowed;
  }

  // Label
  .eb-input-label {
    color: $color-neutral-60;
    font: $font-paragraph-16;
    margin-bottom: 8px;
  }

  // Error
  .eb-input-error {
    margin-top: 4px;
    color: $color-status-error;
    font: $font-paragraph-14;
  }

  @media (max-width: $mobile) {
    .eb-input-label {
      margin-bottom: 8px;
    }
  }
}
