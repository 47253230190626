@import 'theme/variables';

.eb-paragraph {
  color: $color-neutral-60;
  margin: 0;

  // Align
  &.eb-paragraph-center {
    text-align: center;
  }

  // Color
  &.eb-paragraph-white {
    color: $color-neutral-0;
  }

  // Size
  &.eb-paragraph-22 {
    font: $font-paragraph-22;
  }

  &.eb-paragraph-18 {
    font: $font-paragraph-18;
  }

  &.eb-paragraph-16 {
    font: $font-paragraph-16;
  }

  &.eb-paragraph-14 {
    font: $font-paragraph-14;
  }

  &.eb-paragraph-12 {
    font: $font-paragraph-12;
  }

  // Utilities
  &.eb-paragraph-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
