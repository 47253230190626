@import 'src/theme/variables';

.eb-dropdown_item_button {
  .eb-button_text-text {
    border-radius: initial;
    color: $color-neutral-100;
    text-align: left;

    .eb-icon {
      color: $color-neutral-100;
    }

    &:hover {
      background-color: $color-neutral-20;
      color: $color-neutral-100;

      .eb-icon {
        color: $color-neutral-100;
      }
    }
  }
}
