@import 'theme/variables';

.eb-modal_file_upload_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 70px;

  .eb-modal_upload_file-content {
    margin-top: 60px;
  }

  .eb-modal_file_upload_success-img {
    width: 64px;
    height: 64px;
    color: $color-status-success;
  }

  .eb-modal_file_upload_success-heading {
    margin: 20px 0;
  }

  .eb-modal_file_upload_success-button {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
