@import 'src/theme/variables';

.eb-people {
  .eb-table {
    margin-top: 32px;
  }

  .eb-people-total {
    margin-bottom: 14px;
  }

  .eb-people-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-filter-buttons {
      display: flex;
      gap: 8px;
    }
  }

  .eb-people-record-status {
    text-transform: capitalize;
  }

  .eb-people-record-id {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    white-space: nowrap;
    display: block;
  }

  .eb-people-record-date {
    min-width: 98px;
  }

  .eb-search {
    margin-bottom: 20px;
  }

  &-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 26px;

    & + .eb-table {
      margin-top: 0;
    }
  }
}
