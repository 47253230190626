@import 'src/theme/variables';

.eb-file_list_item {
  display: flex;
  align-items: center;

  &-info {
    margin-right: 32px;
  }

  &-info-heading {
    word-break: break-word;
  }

  &-icon {
    background: $color-additional-ultra-light;
    color: $color-additional-ultra-dark;
    border-radius: 16px;
    padding: 12px;
    margin-right: 20px;
  }

  & + & {
    margin-top: 24px;
  }

  &-status {
    margin-left: auto;
    text-align: right;
  }
}
