@import 'src/theme/variables';

.eb-input_number {
  &.eb-input_number-has-icon {
    position: relative;

    // Padding to account for icon
    .eb-input-control {
      padding: 9px 48px 9px 40px;
    }

    // Icon styles
    .eb-input_number-icon {
      position: absolute;
      left: 12px;
      top: 9px;
      width: 20px;
      height: 20px;
    }
  }
}
