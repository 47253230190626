@import 'src/theme/variables';

.eb-mfa_setup {
  text-align: center;

  .eb-mfa_setup-heading {
    margin-bottom: 24px;
  }

  .eb-mfa_setup-button {
    margin: 0 auto;
  }
}
