@import 'src/theme/variables';

.eb-summary_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $color-neutral-30;
  border-radius: 16px;
  padding: 20px;

  &-heading {
    margin-bottom: 30px;

    &-tooltip.eb-tooltip .eb-tooltip-text {
      min-width: 140px;
      left: -10px;

      &.eb-tooltip-arrow-bottom-left {
        bottom: calc(100% + 6px);

        ::after {
          left: 15px;
        }
      }
    }
  }

  &-heading,
  &-summary,
  &-summary-info {
    display: flex;
    justify-content: space-between;
  }

  &-summary {
    gap: 10px;
  }

  &-summary-info {
    align-items: center;

    &-icon {
      margin-right: 8px;
    }

    &-total {
      font: $font-heading-22;
    }
  }

  &-summary-link {
    font-weight: 500;
    align-self: center;
  }
}
