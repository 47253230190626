@import 'src/theme/variables';

.eb-layout_page {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 71px 1fr auto;

  .eb-layout_page-content {
    display: flex;
    padding: 32px $content-side-padding 32px;
    justify-content: center;
  }

  .eb-layout_page-body {
    max-width: $content-desktop;
    width: 100%;
    height: 100%;
    padding: 10px 0 10px 60px;
  }

  .eb-layout_page-heading {
    margin-bottom: 32px;
  }

  .eb-footer {
    padding: 100px 0 24px;
  }

  .eb-breadcrumbs {
    margin: 12px 0 40px;
  }

  @media (max-width: 945px) {
    .eb-layout_page-body {
      max-width: 720px;
    }
  }

  @media (max-width: 720px) {
    .eb-layout_page-content {
      padding: 32px $content-side-padding 0;
    }

    .eb-layout_page-body {
      width: 100%;
      margin: 0 auto;
      padding: 0 10px;
    }
  }
}
