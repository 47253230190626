@import 'theme/variables';

.eb-reset_password-success {
  .eb-reset_password-success-image {
    height: 60px;
    width: 60px;
    display: block;
    margin: auto;
    background: url('/assets/media/checkmark_success.svg') no-repeat;
    background-size: cover;
  }

  .aura-heading_v2 {
    margin: 26px auto 24px;
  }

  .aura-button_text {
    width: 128px;
    margin: 16px auto 0;
  }

  .eb-button_text {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
