@import 'src/theme/variables';

.eb-dashboard {
  & &-heading {
    margin-bottom: 12px;
  }

  &-programs {
    margin-bottom: 32px;
    color: $color-neutral-60;

    &-item {
      text-transform: capitalize;
    }

    &-divider {
      margin: 0 12px;
    }
  }

  & &-subheading {
    margin-bottom: 24px;
  }

  &-summaries {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-bottom: 40px;
  }

  &-file {
    display: grid;
    align-items: center;
    grid-template-columns: auto 132px;
    column-gap: 32px;
    justify-content: space-between;
    border: 1px solid $color-neutral-30;
    border-radius: 16px;
    padding: 24px;

    &-details-errors {
      color: $color-status-error;
    }

    &-details-errors,
    & &-details-secondary {
      margin-top: 12px;
    }

    & &-details-secondary {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-file-details-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 16px;

    &-heading {
      word-break: break-all;
    }

    &-status {
      white-space: nowrap;
      margin-top: 4px;
    }
  }

  &-onboarding {
    background: $color-neutral-10;
    border-radius: 24px;
    display: grid;
    grid-template-columns: auto 48px;
    padding: 32px;
    gap: 24px;
    margin-bottom: 32px;

    & &-heading {
      margin-bottom: 8px;
    }

    & &-button {
      margin-top: 24px;
    }

    &-image {
      width: 48px;
      height: 48px;
    }
  }

  @media (max-width: $mobile) {
    &-file {
      grid-template-columns: auto;

      &-details-main {
        flex-direction: column;
      }

      &-details-errors,
      & &-details-secondary {
        margin-bottom: 24px;
      }
    }
  }
}
