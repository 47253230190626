@import 'src/theme/variables';

.eb-user_management_user {
  display: flex;
  padding: 24px 0;
  border-bottom: 1px solid $color-neutral-20;
  column-gap: 20px;
  align-items: center;

  .eb-user_management_user-heading {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .eb-user_management_user-paragraph {
      font: $font-heading-18;
      color: $color-neutral-100;
      padding-bottom: 5px;
    }
  }

  .eb-user_management_user-sub-text {
    font: $font-paragraph-16;
    color: $color-neutral-60;
    overflow-y: hidden;
  }
}
