/* stylelint-disable scss/operator-no-unspaced  */
/* stylelint-disable scss/dollar-variable-pattern */

// Sizes
$content-desktop: 1400px;
$nav-desktop-width: 256px;
$content-side-padding: 24px;

// Media Queries
$tablet-landscape: 1024px;
$tablet: 940px;
$mobile: 720px;

// Colors - Blue
$color-brand-primary-100: #1d2f50;
$color-brand-primary-90: #2551b8;
$color-brand-primary-80: #36f;
$color-brand-primary-70: #6694ff;
$color-brand-primary-60: #adceff;
$color-brand-primary-50: #d6e6ff;
$color-brand-primary-40: #ecf4ff;
$color-brand-primary-30: #f5f9ff;
$color-brand-primary-20: #fafcff;

// Colors - Neutral
$color-neutral-110: #161616;
$color-neutral-100: #191c1f;
$color-neutral-70: #4c5570;
$color-neutral-60: #4c5570;
$color-neutral-50: #a3abbf;
$color-neutral-40: #cfd5e3;
$color-neutral-30: #dde2ed;
$color-neutral-20: #edf1f7;
$color-neutral-10: #f7f9fc;
$color-neutral-0: #fff;

// Colors - Service
$color-status-error: #e00909;
$color-status-error-bg: #fff4f0;
$color-status-success: #008542;
$color-status-success-bg: #e9ffd7;
$color-status-warning: #fef9e7;

// Colors - Additional
$color-additional-aqua-dark: #00a399;
$color-additional-aqua-light: #d9fcf1;
$color-additional-ultra-dark: #1c3deb;
$color-additional-ultra-light: #e0edff;
$color-additional-purple-dark: #9909e0;
$color-additional-purple-light: #f7e5ff;
$color-additional-sea-dark: #098ae0;
$color-additional-sea-light: #e0f9ff;
$color-additional-brand-orange: #ff8c5a;
$color-additional-brand-beige: #fefbf2;
$color-additional-grass-green: #40b267;
$color-additional-brand-green: #d2ffaf;
$color-additional-salad-green: #acc700;
$color-additional-yellow: #f7cb19;
$color-additional-overlay: #070b14;
$color-additional-paypal-yellow: #ffc439;
$color-additional-paypal-yellow-dark: #e9ac1b;

// Fonts - paragraph
$font-paragraph-24: 400 24px/30px basel-grotesk, sans-serif;
$font-paragraph-22: 400 22px/28px basel-grotesk, sans-serif;
$font-paragraph-18: 400 18px/24px basel-grotesk, sans-serif;
$font-paragraph-16: 400 16px/22px basel-grotesk, sans-serif;
$font-paragraph-14: 400 14px/18px basel-grotesk, sans-serif;
$font-paragraph-12: 400 12px/14px basel-grotesk, sans-serif;

// Fonts - heading
$font-heading-64: 300 64px/80px clarendon-graphic, serif;
$font-heading-58: 100 58px/67px clarendon-graphic, serif;
$font-heading-52: 500 52px/56px basel-grotesk, serif;
$font-heading-48: 300 48px/64px clarendon-graphic, serif;
$font-heading-46: 500 46px/56px basel-grotesk, sans-serif;
$font-heading-44: 500 44px/54px basel-grotesk, serif;
$font-heading-40-alt: 300 40px/54px clarendon-graphic, serif;
$font-heading-40: 500 40px/54px basel-grotesk, sans-serif;
$font-heading-32: 500 32px/44px basel-grotesk, sans-serif;
$font-heading-26: 500 26px/32px basel-grotesk, sans-serif;
$font-heading-22: 500 22px/28px basel-grotesk, sans-serif;
$font-heading-18: 500 18px/24px basel-grotesk, sans-serif;
$font-heading-16: 500 16px/22px basel-grotesk, sans-serif;
$font-heading-14: 500 14px/18px basel-grotesk, sans-serif;
$font-heading-12: 500 12px/14px basel-grotesk, sans-serif;
