@import 'theme/variables';

.eb-organization_expanded_data {
  margin-bottom: 32px;

  .eb-organization_expanded_data-values {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .eb-organiztion-details-list {
    text-align: right;
  }

  .eb-organiztion-details-list {
    padding: 0;
    margin: 4px 0 0;
    text-transform: capitalize;
    font: $font-heading-16;
  }

  .eb-organization_expanded_onboarding-link {
    font: $font-heading-16;
  }

  .eb-organiztion-details-list li {
    display: inline-block;
    margin-bottom: 2px;
  }
}
