@import 'theme/variables';

.eb-dropdown_icon {
  cursor: pointer;
  position: relative;

  .eb-dropdown-nav {
    background-color: $color-neutral-0;
    border-radius: 12px;
    box-shadow: 0 2px 12px RGBA(0 0 0 / 12%); /* stylelint-disable-line function-name-case */
    color: $color-neutral-100;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    width: 184px;
    z-index: 2;
  }

  .eb-dropdown_item-container {
    cursor: pointer;
    padding: 9px 16px;
    width: 100%;

    &:hover {
      background-color: $color-neutral-30;
    }
  }

  .eb-button_icon {
    &:active,
    &:focus {
      .eb-icon {
        color: $color-neutral-30;
      }
    }
  }

  .eb-button_icon-click {
    button {
      background-color: $color-neutral-30;
      border-radius: 50%;

      svg {
        color: $color-neutral-100;
      }
    }

    &:hover {
      &.eb-button_icon .eb-button_icon-button.eb-button_icon-button-secondary {
        background-color: $color-neutral-30;
      }
    }
  }

  @media (max-width: 720px) {
    .eb-dropdown-nav {
      left: unset;
      right: -15px;
    }
  }
}
