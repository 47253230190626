@import 'src/theme/variables';

.eb-filter {
  border-top: 1px solid $color-neutral-20;
  border-bottom: 1px solid $color-neutral-20;
  padding: 24px 0 32px;

  &-hidden {
    display: none;
  }

  &-item {
    margin-bottom: 24px;

    & &-heading {
      margin-bottom: 12px;
    }

    &-inputs {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

      .eb-input {
        margin-top: 0;
      }
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &-tags-option {
    border: 1px solid $color-neutral-30;
    border-radius: 8px;
    padding: 5px 12px;
    cursor: pointer;

    &-selected {
      border-color: $color-neutral-100;
      background-color: $color-neutral-10;
    }
  }

  &-buttons {
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    &,
    &-right {
      display: flex;
      gap: 16px;
    }

    & &-left {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .eb-button_text {
      min-width: 150px;
    }
  }
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
