@import 'theme/variables';

.eb-search {
  .eb-search-form {
    display: grid;
    grid-template-columns: auto 128px;
    gap: 12px;
    align-items: flex-start;
  }

  .eb-search-form-input-text {
    position: relative;
  }

  .eb-input {
    display: inline-block;
    width: 100%;

    .eb-input-control {
      padding-left: 48px;
    }
  }

  .eb-search-button {
    min-width: 128px;
    margin-top: 16px;
  }

  .eb-search-icon-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 16px;

    .eb-icon {
      width: 20px;
      height: 20px;
    }

    &.eb-search-icon-close-disabled {
      cursor: no-drop;
      opacity: 50%;
    }

    .eb-button_icon-button:hover {
      background: transparent;
    }
  }

  .eb-search-icon-search {
    position: absolute;
    margin-top: 25px;
    margin-left: 15px;
    z-index: 1;
  }

  .eb-search-total-records {
    font: $font-heading-16;
    color: $color-neutral-100;
  }

  .eb-search-suggestions {
    margin-top: 16px;
  }
}
