@import 'src/theme/variables';

.eb-modal_invite_user {
  & &-heading {
    margin-bottom: 24px;
  }

  &-callout {
    margin-bottom: 32px;
  }

  &-field {
    display: grid;
    grid-template-columns: auto 292px;
    align-items: center;

    &-label,
    & &-input {
      margin: 8px 0;
    }
  }

  .eb-modal-content {
    overflow-y: unset;
  }

  @media (max-width: $mobile) {
    &-field {
      grid-template-columns: auto;
    }
  }
}
