@import 'theme/variables';

.eb-api {
  &,
  .eb-paragraph {
    color: $color-neutral-110;
  }

  &-page-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    box-shadow: 0 4px 0 -2px $color-neutral-30;
    margin-bottom: 48px;

    & &-link.eb-anchor.eb-anchor {
      padding-bottom: 24px;
      border-bottom: 3px solid transparent;
      font: $font-heading-16;
      color: $color-neutral-70;

      &:active,
      &:hover {
        color: $color-neutral-100;
        text-decoration: none;
        border-bottom: 3px solid $color-brand-primary-80;
      }
    }
  }

  .eb-heading-22,
  .eb-heading-26 {
    margin-bottom: 22px;
  }

  .eb-heading-18 {
    margin: 12px 0;
  }

  &-section {
    margin-bottom: 40px;

    li {
      color: $color-neutral-110;
    }

    .eb-accordion-top-bar {
      font: $font-heading-18;
    }
  }

  & &-section-link {
    font: $font-heading-16;
    text-decoration: underline;
  }

  & &-section-link-block {
    padding: 16px 40px;
    background-color: $color-neutral-10;
    border-left: 4px solid $color-neutral-100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    &:hover {
      background-color: $color-brand-primary-30;
    }

    .eb-api-section-link-title {
      font: $font-paragraph-22;
      margin-bottom: 8px;
    }

    .eb-icon {
      transform: rotateZ(90deg);
    }
  }

  &-section-tag {
    & + & {
      margin-top: 22px;
    }

    .eb-heading-22 {
      text-transform: capitalize;
    }
  }

  &-section-endpoint-block {
    margin-top: 22px;
    padding-bottom: 24px;
    border-bottom: 1px solid $color-neutral-30;

    strong {
      display: inline-block;
      margin-bottom: 12px;
    }
  }
}
