@import 'src/theme/variables';

.eb-input {
  /* Create a custom checkbox container */
  .eb-input_checkbox-container {
    background: $color-neutral-0;
    border: 1px solid $color-neutral-50;
    border-radius: 4px;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
    box-shadow: 0 0 0 2px transparent, 0 0 0 4px transparent;
  }

  /* Focus */
  &.eb-input-focus .eb-input_checkbox-container {
    box-shadow: 0 0 0 2px $color-neutral-0, 0 0 0 4px $color-brand-primary-60;
  }
}

/* The Container */
.eb-input_checkbox {
  display: block;
  padding-left: 28px;
  position: relative;

  .eb-input_checkbox-text {
    font: $font-paragraph-16;
    margin: 8px;
    vertical-align: top;
  }

  /* Style the checkmark */
  .eb-input_checkbox-checkmark::after {
    border: solid $color-neutral-0;
    border-width: 0 2px 2px 0;
    content: '';
    display: none;
    height: 12px;
    left: 7px;
    position: absolute;
    top: 3px;
    transform: rotate(45deg);
    width: 7px;
  }

  /* Hide the browser's default checkbox */
  .eb-input-control {
    cursor: pointer;
    height: 0;
    opacity: 0%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;

    /* On mouse-over, add a hover dark color border */
    &:hover ~ .eb-input_checkbox-container {
      border-color: $color-brand-primary-70;
      cursor: pointer;
    }

    /* disabled */
    &:disabled ~ .eb-input_checkbox-container {
      background-color: $color-neutral-10;
      border-color: $color-neutral-30;
      cursor: not-allowed;
    }

    /* checked */
    &:checked ~ .eb-input_checkbox-container {
      cursor: pointer;
      background-color: $color-brand-primary-80;
      border-color: $color-brand-primary-80;

      &:hover {
        border-color: $color-brand-primary-70;

        &.eb-input_checkbox-checkmark::after {
          border-color: $color-neutral-0;
        }
      }

      &:active {
        background-color: $color-brand-primary-80;
        border-color: $color-brand-primary-80;
      }
    }

    &:checked:hover ~ .eb-input_checkbox-container {
      background-color: $color-brand-primary-70;
      border-color: $color-brand-primary-70;
    }

    /* checked and disabled */
    &:checked:disabled ~ .eb-input_checkbox-container {
      background-color: $color-brand-primary-50;
      border-color: $color-brand-primary-50;
      cursor: not-allowed;

      &:hover {
        background-color: $color-brand-primary-50;
        border-color: $color-brand-primary-50;
      }

      &.eb-input_checkbox-checkmark::after {
        border-color: $color-neutral-0;
      }
    }

    /* Show the checkmark when checked */
    &:checked ~ .eb-input_checkbox-checkmark::after {
      display: block;
    }
  }
}
