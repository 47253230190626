@import 'src/theme/variables';

.eb-dropdown_filter {
  border-radius: 12px;
  background-color: $color-neutral-0;
  color: $color-neutral-100;
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 160px;

  .eb-dropdown_filter-selected {
    border-radius: 12px;
    font: $font-paragraph-16;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    white-space: nowrap;

    &:hover {
      background-color: $color-neutral-20;
    }

    &:focus,
    &:active,
    &.eb-dropdown_filter-selected-focus {
      box-shadow: inset 0 0 0 2px $color-neutral-100;
    }

    &[disabled] {
      box-shadow: none;
      color: #686a6e;
      cursor: no-drop;

      &.eb-dropdown_filter-dropdown {
        color: $color-neutral-50;
        cursor: no-drop;
      }

      &:hover {
        background-color: unset;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .eb-dropdown_filter-dropdown {
    vertical-align: bottom;
    margin-left: 10px;
  }

  .eb-dropdown_filter-list {
    background-color: $color-neutral-0;
    border-radius: 12px;
    // Using uppercase RGBA() to fix known bug in node-sass: https://github.com/sass/node-sass/issues/2251
    box-shadow: 0 4px 16px RGBA(0 0 0 / 8%); /* stylelint-disable-line function-name-case */
    cursor: pointer;
    overflow: hidden;
    padding: 8px 0;
    position: absolute;
    top: 46px;
    width: 160px;
    z-index: 2;

    &-closed {
      display: none;
    }
  }
}
