@import 'src/theme/variables';

.eb-input_code {
  column-gap: 12px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;

  .eb-input_code-input {
    justify-self: center;
    margin: 4px 0;
    width: 36px;

    .eb-input-control {
      &.eb-input_code-input-completed {
        background-color: $color-neutral-20;
        box-shadow: 0 0 0 1px $color-neutral-20;
      }
    }

    &.eb-input_code-input-error {
      .eb-input-control {
        background-color: $color-neutral-0;
        box-shadow: 0 0 0 2px $color-status-error;
      }
    }
  }
}
