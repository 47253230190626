@import 'src/theme/variables';

.eb-modal_confirmation {
  .eb-modal-content {
    height: 534px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & &-heading {
    margin-bottom: 12px;
  }

  & &-text {
    margin-bottom: 24px;
  }

  &-image {
    display: block;
    margin: 0 auto 24px;
    width: 64px;
    height: 64px;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}
