/* stylelint-disable no-descending-specificity */
@import 'src/theme/variables';

.eb-button_icon {
  position: relative;
  display: inline-block;
  width: 40px;
  transition: transform ease-in-out 0.2s;

  &:active {
    transform: scale(0.95);
  }

  .eb-button_icon-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background: transparent;
    transition: all 0.3s ease-in-out;
    padding: 0;

    &:focus-visible {
      outline: 2px solid $color-brand-primary-60;
      outline-offset: 2px;
    }

    &[disabled] {
      cursor: not-allowed;
    }

    // Intent
    &.eb-button_icon-button-primary {
      background-color: $color-neutral-100;

      .eb-icon {
        svg {
          color: $color-neutral-0;
        }
      }

      &[disabled] {
        background-color: $color-neutral-40;

        .eb-icon {
          color: $color-neutral-0;
        }
      }
    }

    &.eb-button_icon-button-secondary {
      .eb-icon {
        svg {
          color: $color-neutral-100;
        }
      }

      &:active {
        background-color: $color-neutral-30;
      }

      &[disabled] {
        .eb-icon {
          svg {
            color: $color-neutral-40;
          }
        }
      }
    }
  }

  @media (hover: hover) {
    .eb-button_icon-button {
      &.eb-button_icon-button-primary {
        &:hover {
          background-color: $color-neutral-60;
        }

        &[disabled] {
          &:hover {
            background-color: $color-neutral-40;

            .eb-icon {
              svg {
                color: $color-neutral-0;
              }
            }
          }
        }
      }

      &.eb-button_icon-button-secondary {
        &:hover {
          background-color: $color-neutral-20;
        }

        &[disabled] {
          &:hover {
            background-color: transparent;

            .eb-icon {
              svg {
                color: $color-neutral-40;
              }
            }
          }
        }
      }
    }
  }
}
