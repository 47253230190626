@import 'src/theme/variables';

.eb-file_details_record_errors {
  padding: 0 0 20px;

  &-heading {
    margin-bottom: 12px;
  }

  &-item {
    color: $color-neutral-60;
    margin-bottom: 12px;
  }
}
