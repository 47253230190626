@import 'src/theme/variables';

.eb-table {
  table {
    width: 100%;
  }

  &-title {
    margin-bottom: 20px;
  }

  &-thead .eb-table-cell {
    padding: 12px 12px 26px 0;
    color: $color-neutral-100;
    text-align: left;

    &,
    .eb-table-heading-button {
      font: $font-heading-16;
    }

    .eb-table-heading-button {
      display: flex;
      align-items: center;
      gap: 4px;

      .eb-table-heading-button-sort-icon {
        display: flex;
        width: 14px;
        height: 14px;
        color: $color-neutral-50;
      }
    }

    .eb-table-heading-button-active {
      .eb-table-heading-button-sort-icon {
        color: $color-neutral-100;

        &-desc {
          transform: scale(-1);
        }
      }
    }
  }

  &-thead,
  &-row {
    box-shadow: inset 0px -1px 0px $color-neutral-20;
  }

  &-expanded-row {
    box-shadow: inset 0px -1px 0px $color-neutral-20, 0px -3px 0px 0px $color-neutral-0;
  }

  &-row .eb-table-cell:not(&-row-expand-icon-cell) {
    padding: 12px 24px 14px 0;
    color: $color-neutral-60;
  }

  &-row-expand-icon-cell {
    .eb-button_icon,
    .eb-button_icon-button {
      width: 20px;
    }

    .eb-icon.eb-icon-exception svg {
      color: $color-neutral-100;
    }
  }

  &-row-checkbox {
    .eb-input {
      margin: 0;
    }

    .eb-input_checkbox {
      padding-left: 0;
    }
  }

  &-placeholder .eb-table-cell {
    padding: 10px 0;
  }

  &-loading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 18px;
  }

  &-loading-row {
    background: $color-neutral-10;
    padding: 18px;
    border-radius: 8px;

    animation-duration: 1.75s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: opacity;

    @keyframes opacity {
      0% {
        opacity: 100%;
      }

      50% {
        opacity: 40%;
      }

      100% {
        opacity: 100%;
      }
    }
  }
}
