.eb-forgot_password {
  .eb-forgot_password-heading {
    margin-bottom: 32px;
  }

  .eb-forgot_password-sub-heading {
    margin-top: 12px;
  }

  .eb-button_text {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
