@import 'src/theme/variables';

.eb-tag_status {
  display: flex;
  align-items: center;
  font: $font-heading-16;

  .eb-icon {
    height: 20px;
    margin-right: 6px;
    margin-top: 0;
    width: 20px;
  }

  // Statuses
  &.eb-tag_status-success {
    color: $color-status-success;
  }

  &.eb-tag_status-warning {
    color: $color-additional-brand-orange;
  }

  &.eb-tag_status-error {
    color: $color-status-error;
  }

  &.eb-tag_status-pending {
    color: $color-neutral-50;
  }
}
