@import 'src/theme/variables';

.eb-no_files {
  margin-top: 150px;
  text-align: center;

  &-image {
    width: 64px;
    height: 64px;
  }

  &-image,
  &-text {
    margin-bottom: 24px;
  }

  &-heading {
    margin-bottom: 12px;
  }

  &-button {
    width: 158px;
    margin: 0 auto;
  }
}
