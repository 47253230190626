@import 'src/theme/variables';

.eb-file_details_error {
  margin-top: 64px;

  &-heading {
    margin-bottom: 10px;
  }

  &-icon {
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto 24px;
  }

  &-message {
    max-width: 544px;
    margin: 0 auto 32px;

    .eb-anchor {
      font-weight: 500;
    }
  }

  &-button {
    margin: 0 auto;
  }
}
