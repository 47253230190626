// SCSS mixin to simplify @font-face loading
@mixin basel-grotesk($variant, $weight) {
  @font-face {
    font-display: auto;
    font-family: basel-grotesk;
    font-weight: $weight;
    font-style: normal;
    // stylelint-disable-next-line declaration-colon-newline-after
    src: (url('./basel-grotesk-#{ $variant }.otf') format('truetype'));
    src: (url('./basel-grotesk-#{ $variant }.woff') format('truetype'));
    src: (url('./basel-grotesk-#{ $variant }.woff2') format('truetype'));
  }
}

@include basel-grotesk(regular, 400);
@include basel-grotesk(medium, 500);
