@import 'theme/variables';

.eb-logo {
  display: flex;
  align-items: baseline;

  .eb-logo-partner {
    margin-left: 16px;
    padding: 0 16px;
    border-left: 2px solid $color-neutral-40;
    font: $font-heading-22;

    &.eb-logo-partner-company {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-neutral-100;
    }

    &.eb-logo-partner-portal {
      color: $color-neutral-60;
      font: $font-paragraph-24;
    }
  }

  &.eb-anchor.eb-anchor-primary:hover:not(.eb-anchor-disabled) {
    text-decoration: none;
  }

  .eb-logo-change-link {
    font: $font-heading-16;
  }

  @media (max-width: $mobile) {
    .eb-logo-partner {
      font: $font-heading-18;

      &.eb-logo-partner-company {
        max-width: 200px;
      }
    }
  }
}
