@import 'src/theme/variables';

$height: 40px;
$li-padding: 14px;

.eb-input_select {
  position: relative;
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;

  .eb-input_select-selected {
    background-color: $color-neutral-0;
    color: $color-neutral-40;
    box-shadow: 0 0 0 1px $color-neutral-40;
    border-radius: 12px;
    font: $font-paragraph-16;
    height: $height;
    line-height: $height;
    overflow: hidden;
    padding: 0 30px 0 10px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      box-shadow: 0 0 0 2px $color-brand-primary-80;
      background-color: unset;
      outline: none;
    }

    &:hover {
      background-color: $color-neutral-0;
      box-shadow: 0 0 0 1px $color-neutral-50;
    }

    &[disabled] {
      background-color: $color-neutral-10;
      box-shadow: 0 0 0 1px $color-neutral-20;
      color: $color-neutral-40;
      cursor: no-drop;

      &.eb-input_select-caret {
        color: $color-neutral-40;
        cursor: no-drop;
      }
    }

    &.eb-input_select-selected-open {
      background-color: $color-neutral-0;
      box-shadow: 0 0 0 2px $color-brand-primary-80;
    }

    &.eb-input_select-selected-active {
      color: $color-neutral-100;
    }
  }

  .eb-input_select-caret {
    position: absolute;
    right: 8px;
    top: 7px;
    color: $color-neutral-100;

    &[disabled] {
      color: $color-neutral-40;
    }
  }

  .eb-input_select-list {
    background-color: $color-neutral-0;
    border-top: none;
    // Using uppercase RGBA() to fix known bug in node-sass: https://github.com/sass/node-sass/issues/2251
    box-shadow: 0 4px 16px RGBA(0 0 0 / 8%); /* stylelint-disable-line function-name-case */
    border-radius: 12px;
    cursor: pointer;
    margin-top: 2px;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .eb-input_select-list-hidden {
    display: none;
  }

  .eb-input_select-list-item {
    font: $font-paragraph-16;
    list-style: none;
    margin: 3px;
    position: relative;

    &:not(:empty) {
      padding: 10px 10px 10px 30px;
    }

    &:hover {
      background-color: $color-neutral-20;
    }

    &[disabled] {
      color: $color-neutral-40;
      cursor: no-drop;
    }

    .ist-icon {
      height: 10px;
      left: 13px;
      position: absolute;
      width: 10px;
    }
  }

  .eb-input_select-list-item-icon {
    position: absolute;
    right: 8px;
    top: 4px;
  }

  @media (max-width: $mobile) {
    .eb-input_select-selected {
      padding-left: 8px;
      text-align: left;
    }
  }
}
