@import 'theme/variables';

.eb-accordion {
  background-color: $color-neutral-0;
  max-width: 100%;
  position: relative;

  .eb-accordion-heading-container {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .eb-accordion-top-bar {
    width: 100%;
  }

  .eb-accordion-icon {
    font: $font-paragraph-24;
    padding: 4px;
    color: $color-brand-primary-90;
  }

  &.eb-accordion-active {
    padding-bottom: 12px;
  }

  .eb-accordion-arrow-background-active:hover {
    background-color: unset;
  }

  &.eb-accordion-disabled {
    cursor: no-drop;

    .eb-accordion-heading-container:hover {
      cursor: no-drop;
    }
  }
}
