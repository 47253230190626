@import 'src/theme/variables';

.eb-aria_button {
  &.eb-aria_button-link {
    border: none;
    color: $color-neutral-100;
    display: inline-block;
    font: $font-paragraph-16;
    padding: 0;

    &:hover {
      cursor: pointer;
    }

    &.eb-aria_button-disabled {
      cursor: no-drop;
      opacity: 30%;
    }
  }

  // Type - primary
  &.eb-aria_button-primary {
    color: $color-brand-primary-80;
    font: $font-heading-16;

    &:active,
    &:focus:not(.eb-aria_button-disabled) {
      color: $color-brand-primary-80;
    }

    &.eb-aria_button-disabled {
      color: $color-brand-primary-60;
      text-decoration: none;
    }

    &:hover:not(.eb-aria_button-disabled) {
      color: $color-brand-primary-80;
      text-decoration: underline;
    }
  }

  // Type - secondary
  &.eb-aria_button-secondary {
    color: $color-neutral-60;
    text-decoration: underline;

    &:active,
    &:focus:not(.eb-aria_button-disabled) {
      color: $color-neutral-60;
    }

    &.eb-aria_button-disabled {
      color: $color-neutral-40;
    }

    &:hover:not(.eb-aria_button-disabled) {
      color: $color-neutral-60;
      text-decoration: none;
    }
  }
}
