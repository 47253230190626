.eb-table_list {
  margin: 32px auto 0;

  .eb-table_list-heading {
    margin-bottom: 24px;
  }

  .eb-table_list-body {
    margin-top: 32px;
  }

  .eb-table_list-error {
    margin: 120px auto 100px;
    text-align: center;
  }

  .eb-table_list-error-heading {
    margin: 35px auto 12px;
  }
}
