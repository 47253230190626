@import 'src/theme/variables';

$arrow-bottom: $color-brand-primary-100 transparent transparent transparent;

.eb-tooltip {
  position: relative;
  cursor: pointer;

  .eb-tooltip-text {
    position: absolute;
    background-color: $color-brand-primary-100;
    border-radius: 12px;
    color: $color-neutral-0;
    max-width: 480px;
    padding: 9px 20px;
    text-align: center;
    font: $font-paragraph-16;
    z-index: 9;

    &::after {
      content: '';
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }

    &.eb-tooltip-arrow-bottom {
      bottom: 100%;

      &::after {
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-color: $arrow-bottom;
      }
    }

    &.eb-tooltip-arrow-bottom-left {
      bottom: 100%;

      &::after {
        top: 100%; /* At the bottom of the tooltip */
        left: 25px;
        border-color: $arrow-bottom;
      }
    }

    &.eb-tooltip-arrow-bottom-right {
      bottom: 100%;

      &::after {
        top: 100%; /* At the bottom of the tooltip */
        right: 25px;
        border-color: $arrow-bottom;
      }
    }

    &.eb-tooltip-arrow-top {
      &::after {
        bottom: 100%; /* At the top of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-color: transparent transparent $color-brand-primary-100 transparent;
      }
    }

    &.eb-tooltip-arrow-right {
      top: 5px;

      &::after {
        top: 50%;
        left: 100%; /* To the right of the tooltip */
        margin-top: -5px;
        border-color: transparent transparent transparent $color-brand-primary-100;
      }
    }

    &.eb-tooltip-arrow-left {
      top: 5px;

      &::after {
        top: 50%; /* To the left of the tooltip */
        right: 100%;
        margin-top: -5px;
        border-color: transparent $color-brand-primary-100 transparent transparent;
      }
    }

    .eb-paragraph {
      color: $color-neutral-0;
      font: $font-paragraph-16;
    }

    .eb-anchor,
    .eb-aria_link {
      color: $color-neutral-0;
      font: $font-heading-16;
    }
  }

  .eb-tooltip-icon {
    margin: 0 5px;
  }

  @media (max-width: 720px) {
    display: none;
  }
}
