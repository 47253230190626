@import 'src/theme/variables';

.eb-mfa_configure {
  text-align: center;
  margin-top: -88px;

  .eb-mfa_configure-heading {
    margin-bottom: 24px;
    margin-top: -80px;
  }

  .eb-mfa_configure-description {
    margin-bottom: 32px;
  }

  .eb-mfa_configure-text {
    margin-bottom: 24px;
  }

  .eb-mfa_configure-image {
    margin-bottom: 16px;
  }

  .eb-mfa_configure-form {
    max-width: 240px;
    margin: 0 auto;
  }
}
