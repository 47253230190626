@import 'src/theme/variables';

.eb-file_details {
  &-heading {
    margin-bottom: 32px;

    &-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      column-gap: 26px;
    }

    &-text {
      margin-bottom: 12px;
      word-break: break-word;
    }

    &-subheading {
      color: $color-neutral-60;
    }

    &-tag {
      margin: 12px 0;
      white-space: nowrap;
    }
  }

  &-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 64px;

    &-btn {
      align-self: center;
      text-align: right;
    }
  }

  & &-callout {
    padding: 16px 24px;
    margin-bottom: 32px;
  }

  .eb-file_details-table.eb-table .eb-table-cell {
    color: $color-neutral-100;
  }

  &-item-name {
    font-weight: 500;
  }

  &-item-id {
    color: $color-brand-primary-80;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 148px;
    white-space: nowrap;
  }

  &-row-expanded {
    .eb-file_details-item-id {
      word-break: break-all;
      white-space: break-spaces;
    }
  }

  .eb-loading {
    margin-top: 120px;
  }

  @media (max-width: $mobile) {
    &-summary {
      grid-template-columns: auto;
      row-gap: 20px;
    }
  }
}
