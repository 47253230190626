.eb-error_404 {
  &-image {
    display: block;
    width: 50px;
    margin: 0 auto 30px;
  }

  &-paragraph {
    margin: 12px 0 24px;
  }

  &-button {
    margin: 0 auto;
  }
}
