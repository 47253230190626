@import 'src/theme/variables';

.eb-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .eb-loading-spinner {
    margin: 0 auto;
    animation: spin 1s linear infinite;
  }

  .eb-loading-heading {
    margin-top: 32px;
  }

  &.eb-loading-centered {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%) translateY(50%);
  }

  &.eb-loading-primary {
    color: $color-brand-primary-80;
  }

  &.eb-loading-secondary {
    color: $color-neutral-0;
  }
}
