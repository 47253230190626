@import 'src/theme/variables';

.eb-callout {
  border-radius: 16px;
  padding: 24px 24px;

  &-with-icon {
    display: grid;
    grid-template-columns: 38px auto;

    &.eb-callout-with-dismiss {
      grid-template-columns: 38px auto 56px;
    }
  }

  &-with-dismiss {
    grid-template-columns: auto 56px;

    .eb-callout-button {
      margin-left: auto;

      .eb-button_icon {
        width: 24px;
      }

      .eb-button_icon-button {
        width: 24px;
        height: 24px;

        .eb-icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  & &-icon {
    width: 22px;
    height: 22px;

    svg {
      color: $color-neutral-100;
    }
  }

  &-info {
    background-color: $color-neutral-10;

    .eb-callout-icon svg {
      color: $color-neutral-60;
    }
  }

  &-warning {
    background-color: $color-status-warning;
  }

  &-error {
    background-color: $color-status-error-bg;

    .eb-callout-content,
    .eb-callout-icon svg {
      color: $color-status-error;
    }
  }

  @media (max-width: $mobile) {
    &-with-dismiss {
      grid-template-columns: auto 35px;
    }

    &.aura-callout-with-icon.aura-callout-with-dismiss {
      grid-template-columns: 35px auto 35px;
    }
  }
}
