@import 'src/theme/variables';

.eb-badge_icon_text {
  display: flex;
  align-items: center;
  font: $font-heading-14;
  border-radius: 100px;

  .eb-icon {
    border-radius: 50%;
    vertical-align: middle;
  }

  &.eb-badge_icon_text-critical {
    color: $color-status-error;

    .eb-icon {
      color: $color-status-error;
    }
  }

  &.eb-badge_icon_text-pending {
    color: $color-neutral-50;

    .eb-icon {
      color: $color-neutral-50;
    }

    &:after {
      content: '...';
    }
  }

  &.eb-badge_icon_text-success {
    color: $color-status-success;

    .eb-icon {
      color: $color-status-success;
    }
  }

  @media (max-width: 720px) {
    padding: 0;
    border-radius: 50%;

    .eb-icon {
      margin-top: -1px;
    }

    &.eb-badge_icon_text-pending {
      &:after {
        content: '';
      }
    }
  }
}
