@import 'theme/variables';

.eb-heading {
  color: $color-neutral-100;
  margin: 0;
  text-align: left;

  //Size
  &.eb-heading-64 {
    font: $font-heading-64;
  }

  &.eb-heading-48 {
    font: $font-heading-48;
  }

  &.eb-heading-40-alt {
    font: $font-heading-40-alt;
  }

  &.eb-heading-40 {
    font: $font-heading-40;
  }

  &.eb-heading-32 {
    font: $font-heading-32;
  }

  &.eb-heading-26 {
    font: $font-heading-26;
  }

  &.eb-heading-22 {
    font: $font-heading-22;
  }

  &.eb-heading-18 {
    font: $font-heading-18;
  }

  &.eb-heading-16 {
    font: $font-heading-16;
  }

  &.eb-heading-14 {
    font: $font-heading-14;
  }

  &.eb-heading-12 {
    font: $font-heading-12;
  }

  &.eb-heading-58 {
    font: $font-heading-58;
  }

  // Alignment
  &.eb-heading-center {
    text-align: center;
  }

  &.eb-heading-right {
    text-align: right;
  }

  // Color
  &.eb-heading-white {
    color: $color-neutral-0;
  }

  //Utilities
  &.eb-heading-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
