@import 'src/theme/variables';

.eb-button_text {
  display: block;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  padding: 10.5px 36px;
  box-shadow: 0 0 0 2px transparent;
  font: $font-heading-16;
  color: $color-neutral-0;
  transition: transform ease-in-out 0.2s;

  &:active {
    transform: scale(0.95);
  }

  .eb-icon {
    color: $color-brand-primary-80;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  &:focus-visible {
    outline: 2px solid $color-brand-primary-60;
    outline-offset: 2px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  // Intent
  &.eb-button_text-primary {
    background-color: $color-brand-primary-80;

    .eb-icon {
      color: $color-neutral-0;
    }

    &:hover,
    &:active {
      background-color: $color-brand-primary-70;
    }

    &[disabled] {
      background-color: $color-brand-primary-60;
    }
  }

  &.eb-button_text-secondary {
    background-color: $color-brand-primary-40;
    color: $color-brand-primary-80;

    .eb-icon {
      color: $color-brand-primary-80;
    }

    &:hover,
    &:active {
      background-color: $color-brand-primary-50;
    }

    &[disabled] {
      background-color: $color-brand-primary-30;
      color: $color-brand-primary-60;

      .eb-icon {
        color: $color-brand-primary-60;
      }
    }
  }

  &.eb-button_text-text {
    background-color: transparent;
    color: $color-brand-primary-80;

    &:hover,
    &:active {
      background-color: $color-brand-primary-40;
      color: $color-brand-primary-80;

      .eb-icon {
        color: $color-brand-primary-80;
      }
    }

    &[disabled] {
      cursor: default;
      pointer-events: none;

      &,
      &:hover {
        color: $color-brand-primary-60;
      }

      &:hover {
        background-color: transparent;
      }

      .eb-icon {
        color: $color-brand-primary-60;
      }
    }
  }

  // Display
  &.eb-button_text-display-inline {
    display: inline;
  }

  &.eb-button_text-full-width {
    width: 100%;
  }

  //Spinner
  &.eb-button_text-loading {
    padding: 8px 36px;

    .eb-loading-spinner {
      color: $color-neutral-0;
      height: 24px;
    }
  }

  &.eb-button_text-loading-secondary {
    .eb-loading-spinner {
      color: $color-brand-primary-80;
    }
  }
}
