@import 'theme/variables';

.aura-modal_file_upload_uploading {
  margin-top: 124px;
  flex-grow: 1;

  .aura-heading {
    margin-top: 16px;
  }
}
