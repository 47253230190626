@import 'src/theme/variables';

.eb-modal {
  // Core
  .eb-modal-backdrop {
    align-items: center;
    // Using uppercase RGBA() to fix known bug in node-sass: https://github.com/sass/node-sass/issues/2251
    background-color: RGBA(7 11 20 / 70%); /* stylelint-disable-line function-name-case */
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }

  .eb-modal-container {
    /*
    Need margin-left/right for ModalBodyDialog on mobile and margin-top/bottom for desktop media queries
    But margin doesn't affect full screen modals so setting here instead of within media queries
    */
    margin: 32px;
    position: relative;
  }

  .eb-modal-icon-container {
    align-items: center;
    // Using uppercase RGBA() to fix known bug in node-sass: https://github.com/sass/node-sass/issues/2251
    background: RGBA(255 255 255 / 90%); /* stylelint-disable-line function-name-case */
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
    position: absolute;
    right: 10px;
    top: 6px;
    // Position above content
    z-index: 101;

    &:hover {
      cursor: pointer;
      background: $color-neutral-20;
    }
  }

  .eb-modal-content {
    background-color: $color-neutral-0;
    overflow-y: auto;
    max-height: 672px;
    padding: 48px;
    width: 640px;
    z-index: 100;
  }

  // Children element styling:
  // Button
  .eb-button_text {
    padding: 9px 16px;
  }

  .eb-button_text-primary,
  .eb-button_text-secondary {
    &:not(.eb-button_text-full-width) {
      width: 128px;
    }
  }

  // Prevent ButtonIcon from being cut off via overflow hidden
  .eb-dropdown-nav {
    right: 0;
  }

  // Media queries - mobile screens
  @media (max-width: 719px) {
    // Core
    .eb-modal-content {
      padding: 32px;
    }
  }

  @media (max-width: 475px) {
    // Core
    .eb-modal-container {
      margin: 16px;
    }

    .eb-modal-content {
      padding: 24px;
    }
  }

  // Media queries - desktop screens
  @media (max-height: 799px) and (min-width: 720px) {
    // Core
    .eb-modal-backdrop {
      align-items: baseline;
    }
  }
}
