@import 'src/theme/variables';

.eb-people_details {
  &-heading {
    margin: 15px auto;
  }

  .eb-people_details-content,
  .eb-people_details-activity {
    margin: 40px auto;
  }

  .eb-people_details-content-details-table {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    padding: 10px;
  }

  .eb-people_details-edit-button {
    margin-top: 35px;
  }

  .eb-people_details-sub {
    color: $color-brand-primary-90;
    font: $font-heading-16;
    text-decoration: none;
    .eb-icon {
      margin-right: 12px;
    }
  }
}
