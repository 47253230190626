@import 'theme/variables';

.eb-modal_session_expiration {
  &-icon-wrapper {
    width: 64px;
    border-radius: 48px;
    margin: 0 auto;
    padding: 16px;
    background: $color-additional-ultra-light;

    .eb-icon {
      height: 32px;
      width: 32px;
      color: $color-additional-ultra-dark;
    }
  }

  &-header {
    margin: 32px 0 16px;

    &-critical {
      color: $color-status-error;
    }
  }

  &-break {
    display: none;
  }

  &-action-buttons {
    margin-top: 31px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .eb-button_text {
      flex-basis: 140px;
    }
  }

  .eb-button_text.eb-button_text-primary.eb-button_text-theme-light {
    min-width: 139px;
    padding: 10px 16px;
  }

  @media (max-width: 719px) {
    .eb-modal_body_text .eb-modal-content {
      height: 100%;
      width: 90vw;
      border-radius: 24px;
    }
  }

  @media (max-width: 535px) {
    .eb-modal_body_text .eb-modal-content {
      height: 100%;
      width: 90vw;
      border-radius: 24px;
    }

    &-break {
      display: block;
    }
  }

  // Media queries - desktop screens
  @media (max-height: 799px) and (min-width: $mobile) {
    .eb-modal_body_text .eb-modal-content {
      max-height: 100%;
      width: 90vw;
      border-radius: 24px;
    }

    &-break {
      display: block;
    }
  }

  @media (max-height: 479px) and (min-width: $mobile) {
    .eb-modal_body_text .eb-modal-content {
      max-height: 100%;
      width: 90vw;
      border-radius: 24px;
    }

    &-break {
      display: block;
    }
  }
}
