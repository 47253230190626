@import 'theme/variables';

.eb-button_toggle {
  background-color: $color-neutral-20;
  border-radius: 56px;
  display: flex;
  padding: 4px;

  .eb-button_toggle-button {
    background: transparent;
    border: none;
    border-radius: 100px;
    color: $color-neutral-60;
    cursor: pointer;
    font: $font-heading-16;
    height: 44px;
    flex: 1 1 auto;

    &.eb-button_toggle-button-selected {
      background-color: $color-neutral-0;
      // Using uppercase RGBA() to fix known bug in node-sass: https://github.com/sass/node-sass/issues/2251
      box-shadow: 37px 81px 184px RGBA(0 0 0 / 8%); /* stylelint-disable-line function-name-case */
      color: $color-neutral-100;
    }

    &.eb-button_toggle-button-disabled {
      cursor: not-allowed;
    }

    &-disabled:not(&-selected) {
      color: $color-neutral-40;
    }
  }
}
