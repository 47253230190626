@import 'src/theme/variables';

.eb-sign_in {
  .eb-sign_in-heading {
    margin-bottom: 32px;
  }

  .eb-sign_in-links-container {
    margin-top: 24px;
    text-align: center;
  }

  .eb-anchor {
    font: $font-heading-16;
  }
  .eb-button_text {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
