.eb-input_password {
  position: relative;

  .eb-input-control {
    // stylelint-disable-next-line declaration-no-important
    background-position: 100% 50% !important; // Needed to override LastPass styles
  }

  .eb-input_password-icon-toggle {
    position: absolute;
    right: 16px;
    top: 10px;
    cursor: pointer;

    &.eb-icon {
      height: 20px;
      width: 20px;
    }
  }
}
