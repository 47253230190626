@import 'src/theme/variables';

html,
body,
#app {
  height: 100%;
  min-height: 100vh;
}

body {
  box-sizing: border-box;
  font-family: basel-grotesk, sans-serif;
  color: $color-neutral-100;
  margin: 0;
  overscroll-behavior-y: none;
  padding: 0;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

a {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

b,
strong {
  font-weight: 500;
}

// Remove default outline for inputs on some browsers
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
