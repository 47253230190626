.eb-modal_body_text {
  .eb-modal-content {
    border-radius: 24px;
  }

  // Media queries - mobile screens
  @media (max-width: 719px) {
    .eb-modal-content {
      border-radius: unset;
      height: 100vh;
      max-height: none;
      width: 100vw;

      // Instead of creating additional container, target heading for additional spacing
      > .eb-heading {
        margin-top: 32px;
      }
    }
  }

  // Media queries - desktop screens
  @media (max-height: 799px) and (min-width: 720px) {
    .eb-modal-content {
      /*
      .eb-modal-container margin-top/bottom - 32px
      32 * 2 = 64
      */
      max-height: calc(100vh - 64px);
    }
  }

  @media (max-height: 479px) and (min-width: 720px) {
    .eb-modal-content {
      //.eb-modal-container margin-top - 32px
      max-height: calc(100vh - 32px);
    }
  }
}
