@import 'src/theme/variables';

$toast-side-padding: 24px;

.eb-toast {
  display: none;
  height: 56px;
  width: 100%;

  &.eb-toast-visible {
    align-items: center;
    animation: slide 0.5s;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 0 $toast-side-padding;
    left: 0;
    right: 0;
  }

  .eb-toast-icon {
    height: 18px;
    width: 18px;
    margin-right: 7px;
  }

  &.eb-toast-error {
    background-color: $color-status-error-bg;

    .eb-toast-message,
    .eb-toast-icon {
      color: $color-status-error;
    }
  }

  &.eb-toast-success {
    background-color: $color-status-success-bg;

    .eb-toast-message,
    .eb-toast-icon {
      color: $color-status-success;
    }
  }

  &.eb-toast-warning {
    background-color: $color-status-warning;

    .eb-toast-message,
    .eb-toast-icon {
      color: $color-neutral-100;
    }
  }

  @keyframes slide {
    0% {
      transform: translateY(-100%);
    }

    50% {
      transform: translateY(8%);
    }

    65% {
      transform: translateY(-4%);
    }

    80% {
      transform: translateY(4%);
    }

    95% {
      transform: translateY(-2%);
    }

    100% {
      transform: translateY(0%);
    }
  }
}
