@import 'src/theme/variables';

.eb-breadcrumbs {
  margin: 0;
  padding-left: 0;

  .eb-icon.eb-icon {
    width: 8px;
    height: 18px;
    vertical-align: middle;
    margin: 0 8px;

    svg {
      color: $color-neutral-60;
    }

    &:first-child {
      display: none;
    }
  }

  .eb-breadcrumbs-link.eb-anchor {
    font: $font-paragraph-14;
  }

  .eb-breadcrumbs-list {
    display: inline;

    &:last-child > .eb-breadcrumbs-link {
      cursor: default;
      color: $color-neutral-60;
      text-decoration: none;
    }
  }

  @media (max-width: $mobile) {
    display: none;
  }
}
