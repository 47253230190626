@import 'src/theme/variables';

.eb-anchor {
  // Global Anchor Styles
  font-weight: 400;
  text-decoration: none;

  &.eb-anchor-disabled {
    cursor: not-allowed;
  }

  // Intent - primary
  &.eb-anchor-primary {
    color: $color-brand-primary-80;

    &:active,
    &:focus:not(.eb-anchor-disabled) {
      color: $color-brand-primary-80;
    }

    &.eb-anchor-disabled {
      color: $color-brand-primary-60;
      text-decoration: none;
    }

    &:hover:not(.eb-anchor-disabled) {
      color: $color-brand-primary-80;
      text-decoration: underline;
    }
  }

  // Intent - secondary
  &.eb-anchor-secondary {
    color: $color-neutral-60;
    text-decoration: underline;

    &:active,
    &:focus:not(.eb-anchor-disabled) {
      color: $color-neutral-60;
    }

    &.eb-anchor-disabled {
      color: $color-neutral-40;
    }

    &:hover:not(.eb-anchor-disabled) {
      color: $color-neutral-60;
      text-decoration: none;
    }
  }

  // Intent - tertiary
  &.eb-anchor-tertiary {
    color: $color-neutral-100;
    text-decoration: underline;

    &:active,
    &:focus:not(.eb-anchor-disabled) {
      color: $color-neutral-100;
    }

    &.eb-anchor-disabled {
      color: $color-neutral-60;
    }

    &:hover:not(.eb-anchor-disabled) {
      color: $color-neutral-100;
      text-decoration: none;
    }
  }

  // Intent - inverted
  &.eb-anchor-inverted {
    color: $color-neutral-100;
    text-decoration: none;

    &:active,
    &:focus:not(.eb-anchor-disabled) {
      color: $color-neutral-100;
    }

    &.eb-anchor-disabled {
      color: $color-neutral-60;
    }

    &:hover:not(.eb-anchor-disabled) {
      color: $color-neutral-100;
      text-decoration: underline;
    }
  }
}
