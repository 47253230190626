@import 'src/theme/variables';

.eb-files_list_item {
  padding: 16px;
  border-radius: 16px;

  &:nth-child(2n + 1) {
    background-color: $color-brand-primary-20;
  }

  & + & {
    margin-top: 12px;
  }

  &-row {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin: 8px 0;
    &.file-id {
      border-left: 5px solid $color-additional-sea-dark;
      padding-left: 8px;
    }

    &-space-between {
      justify-content: space-between;
      gap: 16px;
    }
  }

  &-grid-row {
    display: grid;
    align-items: flex-start;
    gap: 16px;
    grid-template-columns: auto minmax(120px, 200px);
    margin: 8px 0;
  }

  &-info-heading {
    word-break: break-word;
    font: $font-heading-18;
    text-decoration: underline;
  }

  &-icon {
    background: $color-additional-ultra-light;
    color: $color-additional-ultra-dark;
    border-radius: 16px;
    padding: 12px;
  }

  &-status {
    display: block;
    margin-left: auto;
    text-align: right;
  }

  &-error-details {
    margin-top: 12px;
  }

  &-summary {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 8px;
  }

  &-summary-counts {
    display: flex;
    gap: 12px;
    margin-top: 12px;

    &-item {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      border: 1px solid $color-neutral-30;
      border-radius: 16px;
      padding: 12px;
      font: $font-heading-16;

      &-heading {
        margin-bottom: 0;
      }

      &-summary {
        margin-left: auto;
      }

      &-summary-info-total {
        font: $font-heading-18;
      }
    }
  }

  &-summary-review {
    align-self: flex-start;
    margin-top: 10px;
  }

  .eb-dropdown_filter,
  &-summary-review {
    margin-left: auto;
  }

  &-align-right {
    text-align: right;
  }

  &-cta {
    display: inline-block;
    margin-top: 8px;
  }

  @media (max-width: $mobile) {
    &-row,
    &-grid-row {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
