@import 'src/theme/variables';

.eb-bulk_action_management {
  &-heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 10px;
  }

  &-upload-button {
    margin-top: 20px;
  }

  .eb-file_list_item-info {
    flex: 1;
    position: relative;
  }

  &-file-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: $color-neutral-60;
    margin-top: 4px;

    & .file-id {
      border-left: 5px solid $color-additional-sea-dark;
      padding-left: 8px;
    }

    &-cta {
      margin-left: auto;
    }

    & &-type {
      padding: 2px 6px;
      position: absolute;
      top: 0;
      right: -127px;
      background: $color-neutral-50;
      font: $font-heading-12;
      color: $color-neutral-0;

      &.eb-badge_icon_text-success {
        background: $color-neutral-60;
        color: $color-neutral-0;
      }
    }
  }

  &-summary-counts {
    display: flex;
    gap: 12px;
    margin-top: 10px;

    &-item {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      border: 1px solid $color-neutral-30;
      border-radius: 16px;
      padding: 12px;
      font: $font-heading-16;
    }
  }
}
